import React, { FC } from 'react';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { A } from '../styled/miscellaneous/a';
import { H2, H3 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';
import { click } from '../utils/analytics';
import { patchLinkV1, clinetLinkV1 } from '../consts';

const Downloads: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <H2>Downloads</H2>
        <P>
          In order to play in this server you'll need to download 2 files: Lineage2 c6 (interlude)
          client and l2hestia patch. If you already have a client in you computer, you only need to
          download l2hestia patch. Below are download links and step by step installation guide.
        </P>
        <H3>Interlude client download</H3>
        <P>
          <A
            href={clinetLinkV1}
            target="_blank"
            onClick={() => click('Download', 'Client-Yandex')}
            rel="noopener noreferrer"
          >
            Link 1 (Yandex Disk)
          </A>
        </P>
        <H3>l2hestia patch v2.1</H3>
        <P>
          <A
            href={patchLinkV1}
            target="_blank"
            onClick={() => click('Download', 'Patch-Mega')}
            rel="noopener noreferrer"
          >
            Link 1 (mega)
          </A>
        </P>
        <H3>Installation guide</H3>
        <P>
          <b>Step 1:</b> Download Lineage2 c6 (interlude) client and install it to desired location.
          (skip this step if you already have it installed)
          <br />
          <b>Step 2:</b> Go to Lineage2 installation folder and delete "system" folder.
          <br />
          <b>Step 3:</b> Download l2hestia patch and extract it inside Lineage2 installation folder.
          <br />
          <b>Step 4:</b> After extracting l2hestia patch, you should see new "system" folder. Open
          it and click l2.exe.
          <br />
          <b>Step 5:</b> Play the game!
        </P>
      </div>
    </MainCardContainer>
  );
};

export default Downloads;

import useAxios from 'axios-hooks';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { clrError, clrSuccess } from '../styled/colors';
import { ServerStatusContainer } from '../styled/containers/ServerStatusContainer';
import { IServerStatus } from '../types/axios/ServerStatus';
import { MS_IN_MIN } from '../consts';

const BAD_STATUSES = ['off', 'offline', 'down'];
const REFETCH_AFTER = 5 * MS_IN_MIN;

const ServerStatus: FC = () => {
  //   const [{ data, error }, fetch] = useAxios<IServerStatus>({
  //     url: `/game-server/getServerStatus`,
  //     method: 'GET',
  //   });

  //   useEffect(() => {
  //     const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
  //     return () => {
  //       window.clearInterval(interval);
  //     };
  //   }, [fetch]);

  //   if (error || !data) {
  //     return <ServerStatusContainer></ServerStatusContainer>;
  //   }

  //   const { loginStatus, servers } = data;

  const loginStatus = 'On';
  const servers = [{ name: 'Game', status: 'On (Test)' }];

  return (
    <ServerStatusContainer>
      <div style={{ marginRight: 10 }}>
        Login: <span style={{ color: getStatusColor(loginStatus) }}>{loginStatus}</span>
      </div>
      {servers.map(({ name, status }) => (
        <div>
          {name}: <span style={{ color: getStatusColor(status) }}>{status}</span>
        </div>
      ))}
    </ServerStatusContainer>
  );
};

const getStatusColor = (status: string) =>
  BAD_STATUSES.includes(status.toLowerCase()) ? clrError : clrSuccess;

export default ServerStatus;

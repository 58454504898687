import styled from 'styled-components';

export const FormContainer = styled.form`
  width: 100%;
`;

export const FormCell = styled.div`
  width: 100%;
`;

export const FormRow = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-top: 0;
`;

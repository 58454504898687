import useAxios from 'axios-hooks';
import * as React from 'react';
import { FC, useEffect } from 'react';
import CentredLoader from '../miscellaneous/CenteredLoader';
import { useAuth } from '../../hooks/useAuth';

const Logout: FC = () => {
  const { token, setToken } = useAuth();
  const [, logoutPost] = useAxios(
    { url: '/auth/logout', method: 'POST', headers: { authorization: token } },
    { manual: true },
  );

  useEffect(() => {
    const onLogout = () => {
      setToken('');
    };

    logoutPost()
      .then(onLogout)
      .catch(onLogout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CentredLoader />;
};

export default Logout;

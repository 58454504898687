import useAxios from 'axios-hooks';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import useCountdown from '../../hooks/useCountdown';
import { RouterPaths } from '../../routes/RouterPaths';
import {
  clrBlueLight,
  clrDividerMuted,
  clrError,
  clrSuccess,
  clrTextMuted,
} from '../../styled/colors';
import { FormContainer } from '../../styled/containers/FormContainer';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { A } from '../../styled/miscellaneous/a';
import { alert, click, submit } from '../../utils/analytics';
import { isAxiosError } from '../../utils/axiosUtils';
import {
  BAD_CODE_ERROR,
  BAD_PARAMS_ERROR,
  EXISTING_EMAIL_ERROR,
  EXISTING_USERNAME_ERROR,
  TOO_MANY_REQUESTS,
} from '../../utils/errors';
import { isValidEmail } from '../../utils/formikUtils';
import { FormDescriptionRow } from '../form/FormDescriptionRow';
import { FormInputRowField } from '../form/FormInputRow';
import { SubmitButtonRow } from '../form/SubmitButtonRow';

const GA_CAT = 'Register';
export const REG_SUCCESS = 'success';

interface IValues {
  email: string;
  verificationCode: string;
  password: string;
  username: string;
}

const initialValues: IValues = {
  email: '',
  verificationCode: '',
  password: '',
  username: '',
};

const validationSchema = object().shape({
  email: string()
    .email('Email is invalid')
    .required('Email is required'),
  verificationCode: string().required('Verification Code is required'),
  password: string()
    .min(8, 'Password must be at least 8 characters long')
    .max(50, 'Password can not be longer than 50 characters')
    .matches(/[A-Z]/, 'Password must include at least one upper-case letter')
    .matches(/[a-z]/, 'Password must include at least one lower-case letter')
    .matches(/[0-9]/, 'Password must include at least one number')
    .matches(/[@.,$!%*#?&]/, 'Password must include at least one special character (@.,$!%*#?&)')
    .required('Password is required'),
  username: string()
    .min(3, 'Username must be at least 3 characters long')
    .max(15, 'Username can not be longer than 15 characters')
    .required('Username is required'),
});

const RegisterForm: FC = () => {
  const history = useHistory();
  const [, registerPost] = useAxios({ url: '/auth/register', method: 'POST' }, { manual: true });
  const [, sendCode] = useAxios({ url: '/auth/sendcode', method: 'POST' }, { manual: true });
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [resetTime, timeLeft] = useCountdown(60, 0);
  const canSend = timeLeft <= 0;

  const clearMsgs = () => {
    setSuccessMsg('');
    setErrorMsg('');
  };

  const onSendClick = (
    email: string,
    setErrors: (errors: FormikErrors<IValues>) => void,
    setTouched: (touched: FormikTouched<IValues>, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (!canSend) {
      return;
    }
    clearMsgs();
    if (!isValidEmail(email)) {
      setTouched({ email: true });
      setErrors({ email: 'Email is invalid' });
      return;
    }
    sendCode({ data: { email } })
      .catch(e => {
        if (isAxiosError(e, BAD_PARAMS_ERROR)) {
          setTouched({ email: true });
          setErrors({ email: 'Email is invalid' });
        } else if (isAxiosError(e, TOO_MANY_REQUESTS)) {
          setTouched({ verificationCode: true }, false);
          setErrors({ verificationCode: 'Please wait 1 minute and try again.' });
        } else {
          setErrorMsg('Unknown server error. Please try again.');
        }
      })
      .then(res => {
        if (res) {
          resetTime();
          setSuccessMsg('Code sent! Be sure to check SPAM folder.');
        }
      });
  };

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors, resetForm }) => {
        setSuccessMsg('');
        setErrorMsg('');
        setSubmitting(true);
        click(GA_CAT, 'Register');
        try {
          await registerPost({ data: variables });
          setSuccessMsg('Registration successful!');
          resetForm();
          submit(GA_CAT, 'Register');
          setSubmitting(false);
          history.push(`${RouterPaths.LOG_IN}?reg=${REG_SUCCESS}`);
        } catch (error) {
          alert(GA_CAT, 'Register');
          if (isAxiosError(error, BAD_CODE_ERROR)) {
            setErrors({ verificationCode: 'Wrong code.' });
          } else if (isAxiosError(error, EXISTING_USERNAME_ERROR)) {
            setErrors({ username: 'Username already taken.' });
          } else if (isAxiosError(error, EXISTING_EMAIL_ERROR)) {
            setErrors({ email: 'Account with this email already exists.' });
          } else if (isAxiosError(error, TOO_MANY_REQUESTS)) {
            setErrorMsg('Too many register attempts. Try again later.');
          } else {
            setErrorMsg('Unknown server error. Please try again.');
          }
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit, values, setErrors, setTouched }) => (
        <FormContainer
          style={{ width: '270px', margin: '0 auto' }}
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormInputRowField
            rowStyle={{ margin: 0 }}
            id="email"
            name="email"
            type="email"
            placeholder="Email"
          />
          <FormInputRowField
            id="verificationCode"
            name="verificationCode"
            placeholder="Verification Code"
            rightElement={
              <div
                style={{
                  color: canSend ? clrBlueLight : clrTextMuted,
                  cursor: canSend ? 'pointer' : 'not-allowed',
                  height: 40,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
                onClick={() => onSendClick(values.email, setErrors, setTouched)}
              >
                <div
                  style={{
                    width: 120,
                    borderLeft: `solid 1px ${clrDividerMuted}`,
                    textAlign: 'center',
                  }}
                >
                  Send Code{timeLeft ? ` (${timeLeft})` : ''}
                </div>
              </div>
            }
          />
          <FormInputRowField id="username" name="username" placeholder="Username" />
          <FormInputRowField
            id="password"
            name="password"
            type="password"
            placeholder="Password (8 or more characters)"
          />
          <div
            style={{
              marginLeft: 5,
              fontSize: 13,
              lineHeight: '16px',
              marginTop: 5,
              color: clrTextMuted,
            }}
          >
            Password must contain at least one uppercase letter, lowercase letter, number and
            symbol.
          </div>
          {errorMsg && (
            <FormDescriptionRow style={{ color: clrError, marginLeft: 5 }}>
              {errorMsg}
            </FormDescriptionRow>
          )}
          {successMsg && (
            <FormDescriptionRow style={{ color: clrSuccess, marginLeft: 5 }}>
              {successMsg}
            </FormDescriptionRow>
          )}
          <SubmitButtonRow isSubmitting={isSubmitting}>Register</SubmitButtonRow>
        </FormContainer>
      )}
    </Formik>
  );
};

const Register: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer style={{ padding: '25px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <RegisterForm />
        <A
          style={{ paddingTop: 15, marginBottom: 5 }}
          onClick={() => {
            click(GA_CAT, `Back to login`);
            history.push(RouterPaths.LOG_IN);
          }}
          cursorPointer
        >
          Back to Log In
        </A>
      </div>
    </MainCardContainer>
  );
};

export default Register;

import React, { FC } from 'react';
import { RouterPaths } from '../../../routes/RouterPaths';
import NewsPreview from '../NewsPreview';

const RoadMapPreview: FC = () => {
  return (
    <NewsPreview
      redirectPath={RouterPaths.ROAD_MAP}
      title={'Roadmap: See into the future!'}
      img="news/RoadmapPrev"
      dateStr="28 Jun 2020"
    >
      Hey guys,
      <br />
      As you can see, the web page is being updated every week and some social marketing is
      beginning. But when will the server start? When will you be able to begin your adventure? The
      truth is, it’s not that simple. A solid base exists already, but some parts are still missing.
      Website is still missing some core pages and the game server needs to be placed into a machine
      and thoroughly tested. So to put everything into perspective and make it easier to understand,
      I’d like to present a roadmap.
    </NewsPreview>
  );
};

export default RoadMapPreview;

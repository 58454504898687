import styled from 'styled-components';

interface IProps {
  alpha?: number;
}

export const Hr = styled.hr<IProps>`
  border: 0px;
  height: 2px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    10%,
    rgba(211, 209, 221, ${props => (props.alpha === undefined ? 1 : props.alpha)}),
    90%,
    rgba(0, 0, 0, 0)
  );
`;

import React, { FC } from 'react';
import { MainCardContainer } from '../../../styled/containers/MainCardContainer';
import { H2, H4 } from '../../../styled/miscellaneous/hx';
import { Img } from '../../../styled/miscellaneous/img';
import { P } from '../../../styled/miscellaneous/p';

const TITLE = 'Roadmap: See into the future!';
const DATE = '28 Jun 2020';

const Roadmap: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H2>{TITLE}</H2>
          <P style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'right' }}>{DATE}</P>
        </div>
        <Img
          icon="news/Roadmap"
          alt=""
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
          roundCorners
          shadow
        />
        <P>
          Hey guys,
          <br />
          As you can see, the web page is being updated every week and some social marketing is
          beginning. But when will the server start? When will you be able to begin your adventure?
          The truth is, it’s not that simple. A solid base exists already, but some parts are still
          missing. Website is still missing some core pages and the game server needs to be placed
          into a machine and thoroughly tested. So to put everything into perspective and make it
          easier to understand, I’d like to present a roadmap. This roadmap consists of 4 phases.
        </P>
        <H4>Phase 1: Missing pages</H4>
        <P>
          During the first phase, L2 Hestia website will be finished. Missing pages
          (About/Rules/FAQ) will be added and existing pages might be updated.
          <br />
          Estimated time: 2 weeks.
        </P>
        <H4>Phase 2: Running test server</H4>
        <P>
          During the second phase, the test server will be deployed and started. Everyone will be
          able to join and see the server on their own.
          <br />
          Estimated time: 1 week.
        </P>
        <H4>Phase 3: Testing, fixing and forum</H4>
        <P>
          During the third phase, the server will be tested by a test team and also regular players.
          All found bugs will be fixed and maybe some more features will be added (if there will be
          a need for them). Also L2 Hestia forum will open during this phase to make bug reporting
          and feature requests easier.
          <br />
          Estimated time: until no more bugs are found.
        </P>
        <H4>Phase 4: Deploy, advertise and Play</H4>
        <P>
          Fourth and final stage will consist of buying powerful machines to run the server with no
          lags and low ping, advertising the server to attract even more players and, of course,
          playing the game!
          <br />
          Estimated time: 2 weeks
          <br />
          <br />
          See you in the next update!
        </P>
      </div>
    </MainCardContainer>
  );
};

export default Roadmap;

import styled from 'styled-components';

interface IProps {
  italic?: boolean;
}

export const P = styled.p<IProps>`
  margin-bottom: 15px;

  ${props =>
    props.italic &&
    `
    font-style: italic;
  `};
`;

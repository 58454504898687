import * as React from 'react';
import { FC } from 'react';
import { FormRow } from '../../styled/containers/FormContainer';
import { Button } from '../../styled/buttons/Button';

interface IProps {
  isSubmitting: boolean;
  fullW?: boolean;
  slim?: boolean;
}

export const SubmitButtonRow: FC<IProps> = ({ isSubmitting, children, fullW, slim }) => (
  <FormRow style={{ marginTop: '20px' }}>
    <Button
      type="submit"
      style={{
        maxWidth: fullW ? undefined : 320,
        width: '100%',
        fontSize: slim ? 16 : 20,
      }}
      darkBlue
      disabled={isSubmitting}
      slim={slim}
    >
      {children ? children : 'Submit'}
    </Button>
  </FormRow>
);

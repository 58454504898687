import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { A } from '../styled/miscellaneous/a';
import { SB } from '../styled/miscellaneous/b';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';
import { click } from '../utils/analytics';

const Faq: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer>
      <div>
        <H2>Frequently asked questions</H2>
        <P>Here you can find answers to frequently asked questions.</P>
        <P>
          <SB>Q: How do I start playing?</SB>
          <br />
          A: Please visit the “
          <A
            onClick={() => {
              click('Faq', `Play-now`);
              history.push(RouterPaths.PLAY_NOW);
            }}
            cursorPointer
          >
            Play now
          </A>
          ” section of the website.
        </P>
        <P>
          <SB>Q: What are the rates of the server?</SB>
          <br />
          A: xp x1000 sp x1000 aden x1000 drop x1.
        </P>
        <P>
          <SB>Q: What are enchant rates of the server?</SB>
          <br />
          A: success 88%, max +35, safe +4.
        </P>
        <P>
          <SB>Q: What is special about this server?</SB>
          <br />
          A: It is fair, stable and unique. It has many custom items, npcs, zones, bosses and
          quests. You will experience something new and fun while playing here.
        </P>
        <P>
          <SB>Q: How can I donate?</SB>
          <br />
          A: There is no way to donate. L2 Hestia project tries to be fair to everyone. This means
          you have to farm to get the level and items that you want.
        </P>
        <P>
          <SB>Q: Do I need a quest to become a nobless?</SB>
          <br />
          A: No. You can buy the quest item from the Special shop.
        </P>
        <P>
          <SB>Q: Do I need a quest to have a subclass?</SB>
          <br />
          A: No. You can take new subclasses after you reach level 75.
        </P>
        <P>
          <SB>Q: Where can I learn new skills?</SB>
          <br />
          A: All skills are auto learned. When you level up, your skills appear automatically.
        </P>
        <P>
          <SB>Q: Where can I find the game patch?</SB>
          <br />
          A: L2 Hestia patch can be found in the “
          <A
            onClick={() => {
              click('Faq', `Downloads`);
              history.push(RouterPaths.DOWNLOADS);
            }}
            cursorPointer
          >
            Downloads
          </A>
          ” section of the website.
        </P>
        <P>
          <SB>Q: What is the main currency in game?</SB>
          <br />
          A: Monsters drop adena which you can use to buy supplies and S grade items. But the main
          currency is HESTIA letters. They are dropped and spoiled by monsters and raid bosses. Use
          them to buy the best items in the game.
        </P>
        <P>
          <SB>Q: I have never played Lineage2. What to do after I create a character?</SB>
          <br />
          A: Firstly speak with npc that you see after entering the game and teleport to the newbie
          zone. Then buy some no grade items from GM Shop and take buffs from NPC Buffer. After that
          go and kill mobs and lvl up. When you reach level 60-65 find a teleporter again and go to
          65-80 level zone. When there, buy better items for adena that you gathered and continue
          leveling until 80 lvl. Also don’t forget to speak with scientist NPC when you hit levels
          20/40/76, he will let you gain a new profession. After you hit level 80, continue killing
          monsters and getting better items. Consider taking Destroyer class (Orc Fighter -{'>'} Orc
          Raider -{'>'} Destroyer -{'>'} Titan) or subclass. Destroyer uses two-hand swords and has
          skills (Fenzy/Rage/Zealot) that greatly increase your damage. With destroyer class it
          should be easy to kill stronger monsters or even bosses that drop HESTIA letters (for best
          items). If you decide to kill bosses, you will need to find friends and at least one of
          you will have to play Healer class (Human Wizard -{'>'} Cleric -{'>'} Bishop -{'>'}{' '}
          Cardinal). Of course you can always form groups of any classes to kill strong regular mobs
          that also drop HESTIA letters. Finally you can get additional loot from monsters by
          spoiling them. You will need to have Spoiler class (Dwarf fighter -{'>'} Scavenger -{'>'}{' '}
          Bounty Hunter -{'>'} Fortune Seeker). It has a skill called “Spoil” and “Sweep”. Cast
          “Spoil” on monsters while they are alive and “Sweep” after they die to get additional
          loot.
        </P>
      </div>
    </MainCardContainer>
  );
};

export default Faq;

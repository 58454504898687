import styled from 'styled-components';
import { clrWhite } from '../colors';

export const ServerStatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 10px;
  margin: 70px 30px 10px 0;
  font-size: 16px;
  color: ${clrWhite};
`;

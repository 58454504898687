import React, { FC } from 'react';
import NewsPreview from '../NewsPreview';
import { RouterPaths } from '../../../routes/RouterPaths';

const TheBeginingPreview: FC = () => {
  return (
    <NewsPreview
      redirectPath={RouterPaths.THE_BEGINING}
      title={'L2 Hestia: The beginning'}
      img="news/TheBeginingPrev"
      dateStr="20 Jun 2020"
    >
      Greetings everyone,
      <br />
      I’m really happy to announce that L2 Hestia website is finally up! This is the first small
      step in the long journey to come!
      <br /> The idea of L2 Hestia has been in my mind for multiple years already. When I first
      started playing Lineage 2, it felt magical. There is just no other way to describe it. A new
      world, never seen before, filled with secrets, to be found, adventures to be had, friends to
      be made and enemies to be defeated. It seemed that...
    </NewsPreview>
  );
};

export default TheBeginingPreview;

import React, { FC } from 'react';
import { FlexDiv } from '../styled/containers/flex';
import { MainImageContainer } from '../styled/containers/MainImageContainer';
import { Img } from '../styled/miscellaneous/img';
import ServerStatus from './ServerStatus';

const MainImage: FC = () => {
  return (
    <MainImageContainer>
      <ServerStatus />
      <FlexDiv style={{ justifyContent: 'center' }}>
        <Img icon="Valakas" isHiddenMobile style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        <Img icon="HestiaLogo" alt="" style={{ height: '100px', margin: 'auto 30px' }} pointer />
        <Img icon="Antharas" isHiddenMobile style={{ marginTop: 'auto', marginBottom: 'auto' }} />
      </FlexDiv>
    </MainImageContainer>
  );
};

export default MainImage;

import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { H3 } from '../../styled/miscellaneous/hx';
import { Img } from '../../styled/miscellaneous/img';
import { P } from '../../styled/miscellaneous/p';
import { click } from '../../utils/analytics';
import { useWindowSize } from '../miscellaneous/WindowDimensionProvider';

interface IProps {
  title: string;
  redirectPath: string;
  img: string;
  dateStr: string;
}

const NewsPreview: FC<IProps> = ({ title, redirectPath, img, children, dateStr }) => {
  const history = useHistory();

  const { width } = useWindowSize();

  const onClick = () => {
    click('News', `Readmore ${title}`);
    history.push(redirectPath);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: width > 600 ? 'start' : 'center',
        flexDirection: width > 600 ? 'row' : 'column',
      }}
    >
      <Img
        icon={img}
        onClick={onClick}
        alt=""
        style={{
          height: '200px',
          width: '200px',
          margin: '0 15px 5px 0',
        }}
        roundCorners
        shadow
        pointer
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H3 onClick={onClick} style={{ cursor: 'pointer' }} underlineHover>
            {title}
          </H3>
          <P style={{ fontSize: 12, fontWeight: 'bold' }}>{dateStr}</P>
        </div>
        <P style={{ marginBottom: '0px' }}>{children}</P>
      </div>
    </div>
  );
};

export default NewsPreview;

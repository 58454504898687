import React, { FC, useEffect } from 'react';
import { pageview } from 'react-ga';
import { Redirect, useLocation } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import SwitchGenerator, { IRoute } from '../routes/SwitchGenerator';
import About from './About';
import ApplicationWrapper from './ApplicationWrapper';
import Downloads from './Downloads';
import Home from './Home';
import Roadmap from './news/pages/Roadmap';
import SocialMedia from './news/pages/SocialMedia';
import TheBegining from './news/pages/TheBegining';
import Rules from './Rules';
import Faq from './Faq';
import PlayNow from './PlayNow';
import TestServer from './news/pages/TestServer';
import { useAuth } from '../hooks/useAuth';
import Login from './auth/Login';
import Logout from './auth/Logout';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import Account from './account/Account';
import ComingSoon from './ComingSoon';

const RedirectToHome = () => <Redirect to={RouterPaths.HOME} />;
const RedirectToLogin = () => <Redirect to={RouterPaths.LOG_IN} />;

const routes: IRoute[] = [
  {
    path: RouterPaths.DOWNLOADS,
    both: <Downloads />,
  },
  {
    path: RouterPaths.HOME,
    both: <Home />,
  },
  {
    path: RouterPaths.RULES,
    both: <Rules />,
  },
  {
    path: RouterPaths.ABOUT,
    both: <About />,
  },
  {
    path: RouterPaths.FAQ,
    both: <Faq />,
  },
  {
    path: RouterPaths.PLAY_NOW,
    both: <PlayNow />,
  },
  {
    path: RouterPaths.THE_BEGINING,
    both: <TheBegining />,
  },
  {
    path: RouterPaths.SOCIAL_MEDIA_HERE,
    both: <SocialMedia />,
  },
  {
    path: RouterPaths.ROAD_MAP,
    both: <Roadmap />,
  },
  {
    path: RouterPaths.TEST_SERVER,
    both: <TestServer />,
  },
  {
    path: RouterPaths.LOG_OUT,
    auth: <Logout />,
    noAuth: <RedirectToHome />,
  },
  {
    path: RouterPaths.LOG_IN,
    auth: <RedirectToHome />,
    noAuth: <Login />,
  },
  {
    path: RouterPaths.REGISTER,
    auth: <RedirectToHome />,
    noAuth: <Register />,
  },
  {
    path: RouterPaths.FORGOT_PASSWORD,
    auth: <RedirectToHome />,
    noAuth: <ForgotPassword />,
  },
  {
    path: RouterPaths.RESET_PASSWORD,
    auth: <RedirectToHome />,
    noAuth: <ResetPassword />,
  },
  {
    path: RouterPaths.ACCOUNT,
    auth: <Account />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.FORUM,
    both: <ComingSoon />,
  },
  {
    path: RouterPaths.ROOT,
    both: <RedirectToHome />,
  },
];

const ApplicationContext: FC = () => {
  const location = useLocation();
  useEffect(() => {
    pageview(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { token } = useAuth();

  return (
    <ApplicationWrapper>
      <SwitchGenerator authorized={!!token} routes={routes} />
    </ApplicationWrapper>
  );
};

export default ApplicationContext;

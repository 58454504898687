import styled from 'styled-components';
import { css } from 'styled-components';
import {
  clrDarkBlue1,
  clrDivider,
  clrDividerMuted,
  clrError,
  clrGreenishMuted,
  clrText,
  clrTextDark,
  clrTextMuted,
  clrWhiteMist,
} from '../colors';
import { rgba } from 'polished';

const InputBrowserReset = css`
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;

  &::placeholder {
    color: ${clrTextMuted};
  }

  &::-ms-expand,
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    border-color: ${clrDarkBlue1};
  }
`;

export interface IInputProps {
  disabled?: boolean;
  invalid?: boolean;
  type?: string;
  noBorder?: boolean;
}

const DefaultInputStyling = css<IInputProps>`
  ${InputBrowserReset};
  cursor: pointer;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: ${clrWhiteMist};
  padding: 12px 14px;
  color: ${clrTextDark};
  font-size: 15px;
  border: 1px solid ${clrDividerMuted};
  border-radius: 3px;
  z-index: 0;

  ${props =>
    props.disabled &&
    `
    background: ${rgba(clrDivider, 0.7)};
    color: ${rgba(clrText, 0.5)};
  `}

  ${props =>
    props.invalid &&
    `
    color: ${clrError};
    border-color: ${clrError};
    outline: none;
  `}
`;

export const InputWrap = styled.div<IInputProps>`
  position: relative;
  overflow: hidden;

  & > img {
    pointer-events: none;
    position: absolute;
    bottom: 13px;
    right: 15px;
  }
`;

export const FileInputWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  cursor: pointer;

  input[type='text'] {
    z-index: 0;
    width: calc(100% - 110px) !important;
  }

  input[type='file'] {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }

  button,
  a {
    margin-left: 10px;
    width: 100px;
    align-self: flex-end;
    z-index: 0;
  }
`;

export const InfoBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 32px;
  color: ${clrTextMuted};
  background-color: ${clrGreenishMuted};
  font-size: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ILabelProps {
  noColor?: boolean;
}

export const Label = styled.label<ILabelProps>`
  display: block;
  width: 100%;
  ${props =>
    !props.noColor &&
    `
      color: ${clrTextDark};
    `};
  font-size: 13px;
  line-height: 30px;
`;

export const Hint = styled.div`
  display: block;
  width: 100%;
  color: ${clrTextDark};
  font-size: 11px;
  line-height: 20px;
`;

export const Input = styled.input`
  ${DefaultInputStyling};

  ${props =>
    props.noBorder &&
    `
    border: none;
    outline: none;
  `}

  ${props =>
    props.type === 'date' &&
    `
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }
    ::-webkit-calendar-picker-indicator {
      -webkit-appearance: none;
      background: none;
      color: ${clrText};
    }
  `}
  ${props =>
    props.type === 'number' &&
    `
    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  `}
`;

export interface ITextareaProps extends IInputProps {
  height?: number;
}

export const Textarea = styled.textarea<ITextareaProps>`
  ${DefaultInputStyling};
  padding-top: 8px;
  padding-bottom: 8px;
  ${props =>
    props.height !== undefined &&
    `
    height: ${props.height}px;`}
  resize: none;
`;

export const Select = styled.select`
  ${DefaultInputStyling};
`;

export const InputContent = styled.div`
  ${DefaultInputStyling};
  line-height: 32px;
  text-transform: uppercase;
`;

import React, { FC } from 'react';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2, H3 } from '../../styled/miscellaneous/hx';
import { P } from '../../styled/miscellaneous/p';
import ChangePasswordForm from './ChangePasswordForm';

const Account: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <H2>Web account management</H2>
        <P>
          This is a web account management panel. Feel free to change and modify your web account.
        </P>
        <H3>Change password</H3>
        <ChangePasswordForm />
      </div>
    </MainCardContainer>
  );
};

export default Account;

import * as React from 'react';
import { FC } from 'react';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';

const ComingSoon: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <H2>Coming soon...</H2>
        <P>This page is currently being developed. It will be available soon!</P>
      </div>
    </MainCardContainer>
  );
};

export default ComingSoon;

import { createBrowserHistory } from 'history';
import React from 'react';
import { Router } from 'react-router-dom';
import ApplicationContext from './components/ApplicationContext';
import { GlobalStyle } from './styled/globals';
import { pageview } from 'react-ga';
import WindowWidthProvider from './components/miscellaneous/WindowDimensionProvider';
import ScrollToTop from './components/miscellaneous/ScrollToTop';
import AuthProvider from './hooks/useAuth';
import { initAxios } from './utils/axiosUtils';

const history = createBrowserHistory();
initAxios();

history.listen(location => {
  pageview(location.pathname);
});

const App: React.FC = () => {
  return (
    <>
      <WindowWidthProvider>
        <AuthProvider>
          <Router history={history}>
            <ScrollToTop />
            <ApplicationContext />
          </Router>
        </AuthProvider>
      </WindowWidthProvider>
      <GlobalStyle />
    </>
  );
};

export default App;

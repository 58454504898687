export const getNestedValueByString = (obj: any, path: string) => {
  try {
    return path.split('.').reduce((newObject, nextKey) => newObject[nextKey], obj);
  } catch (e) {
    // if path or object was incorrect, no item was found
    return undefined;
  }
};

export const isValidEmail = (email: string) => {
  const re = /\S+@\S+/;
  return re.test(email);
};

import styled from 'styled-components';
import { clrWhite } from '../colors';

interface IProps {
  bgImg?: boolean;
}

export const HeaderContainer = styled.div<IProps>`
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  ${props =>
    props.bgImg &&
    `background-image: linear-gradient(180deg, rgba(27, 40, 62, 0.9) 45%, rgba(25, 37, 55, 0.9) 100%);`}
  border-radius: 0 0 6px 6px;
  top: 0;
  left: 0;
  right: 0;
  color: ${clrWhite};
  padding: 15px 30px;
`;

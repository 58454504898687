import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import { HeaderButton } from '../styled/buttons/HeaderButton';
import { HeaderContainer } from '../styled/containers/HeaderContainer';
import { click } from '../utils/analytics';
import { Div } from '../styled/miscellaneous/div';
import { Img } from '../styled/miscellaneous/img';
import { DropdownHeaderMenu } from '../styled/containers/DropdownHeaderMenu';
import OutsideClose from './miscellaneous/OutsideClose';
import { discordUrl } from '../consts';
import { useAuth } from '../hooks/useAuth';
import { Hr } from '../styled/miscellaneous/hr';

const GA_CAT = 'Header';
const HEADER_BG_SCROLL = 40;
const buttonsLeft = [
  { text: 'HOME', path: RouterPaths.HOME },
  { text: 'ABOUT', path: RouterPaths.ABOUT },
  { text: 'DOWNLOADS', path: RouterPaths.DOWNLOADS },
  { text: 'RULES', path: RouterPaths.RULES },
  { text: 'FORUM', path: RouterPaths.FORUM },
  { text: 'FAQ', path: RouterPaths.FAQ },
  { text: 'DISCORD', url: discordUrl },
];

const Header: FC = () => {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showBg, setShowBg] = useState(window.scrollY > HEADER_BG_SCROLL);
  const { token } = useAuth();
  const authorized = !!token;

  const buttonsRight = authorized
    ? [
        { text: 'ACCOUNT', path: RouterPaths.ACCOUNT },
        { text: 'LOG OUT', path: RouterPaths.LOG_OUT },
      ]
    : [
        { text: 'LOG IN', path: RouterPaths.LOG_IN },
        { text: 'REGISTER', path: RouterPaths.REGISTER },
      ];

  useEffect(() => {
    const test = () => {
      if (window.scrollY > HEADER_BG_SCROLL) {
        setShowBg(true);
      } else {
        setShowBg(false);
      }
    };

    document.addEventListener('scroll', test);
    return () => {
      document.removeEventListener('scroll', test);
    };
  }, []);

  return (
    <HeaderContainer bgImg={showBg}>
      <Div
        isHiddenMobile
        style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: 12 }}
      >
        <Div>
          {buttonsLeft.map(({ path, text, url }) => (
            <HeaderButton
              key={text}
              transparent
              roundCorners
              onClick={() => {
                click(GA_CAT, text);
                if (path) {
                  history.push(path);
                }
                if (url) {
                  window.open(url);
                }
              }}
            >
              {text}
            </HeaderButton>
          ))}
        </Div>
        <Div>
          {buttonsRight.map(({ path, text }) => (
            <HeaderButton
              key={text}
              transparent
              roundCorners
              onClick={() => {
                click(GA_CAT, text);
                history.push(path);
              }}
            >
              {text}
            </HeaderButton>
          ))}
        </Div>
      </Div>
      <OutsideClose onOutsideTrigger={() => setMenuOpen(false)}>
        <Div
          isHiddenDesktop
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          pointer
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Img icon="icons/menu" pointer style={{ margin: '0 5px 0 5px' }}></Img>
        </Div>
        {menuOpen && (
          <DropdownHeaderMenu isHiddenDesktop>
            {buttonsLeft.map(({ path, text, url }) => (
              <HeaderButton
                key={text}
                transparent
                roundCorners
                width="200px"
                height="45px"
                style={{ textAlign: 'left' }}
                onClick={() => {
                  click(GA_CAT, text);
                  setMenuOpen(false);
                  if (path) {
                    history.push(path);
                  }
                  if (url) {
                    window.open(url);
                  }
                }}
              >
                {text}
              </HeaderButton>
            ))}
            <Hr alpha={0.5} style={{ width: 180 }} />
            {buttonsRight.map(({ path, text }) => (
              <HeaderButton
                key={text}
                transparent
                roundCorners
                width="200px"
                height="45px"
                style={{ textAlign: 'left' }}
                onClick={() => {
                  click(GA_CAT, text);
                  setMenuOpen(false);
                  history.push(path);
                }}
              >
                {text}
              </HeaderButton>
            ))}
          </DropdownHeaderMenu>
        )}
      </OutsideClose>
      <div>
        <HeaderButton
          transparent
          whiteBorder
          width="150px"
          padding="10px"
          onClick={() => history.push(RouterPaths.PLAY_NOW)}
        >
          PLAY NOW
        </HeaderButton>
      </div>
    </HeaderContainer>
  );
};

export default Header;

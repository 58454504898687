import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { clinetLinkV1, patchLinkV1 } from '../consts';
import { RouterPaths } from '../routes/RouterPaths';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { A } from '../styled/miscellaneous/a';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';
import { click } from '../utils/analytics';

const PlayNow: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer>
      <div>
        <H2>Play Now</H2>
        <P>Joining L2 Hestia world is easy! All you need to do is follow 3 steps.</P>

        <ol style={{ marginTop: '5px', marginBottom: '5px' }}>
          <li>
            Download and install Lineage2 Interlude (c6) client.{' '}
            <A
              href={clinetLinkV1}
              target="_blank"
              onClick={() => click('Download', 'Client-Yandex')}
              rel="noopener noreferrer"
              cursorPointer
            >
              Download link here
            </A>
            .
          </li>
          <li>
            Download the L2 Hestia patch, delete the “system” folder from Lineage2 installation
            folder and unzip the patch (inside Lineage2 installation folder).{' '}
            <A
              href={patchLinkV1}
              target="_blank"
              onClick={() => click('Download', 'Patch-Mega')}
              rel="noopener noreferrer"
              cursorPointer
            >
              Download link here
            </A>
            .
          </li>
          <li>
            Open the game (click l2.exe inside the new “system” folder), enter your
            username/password (your account will be created automatically), create character and
            start playing!
          </li>
        </ol>
        <P italic>
          If you’re having trouble starting the game or download speed seems low, check out the “
          <A
            onClick={() => {
              click('PlayNow', `Downloads`);
              history.push(RouterPaths.DOWNLOADS);
            }}
            cursorPointer
          >
            Downloads
          </A>
          ” section. It has a detailed installation guide and multiple download mirror links.
        </P>
      </div>
    </MainCardContainer>
  );
};

export default PlayNow;

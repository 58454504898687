import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { RouterPaths } from '../../routes/RouterPaths';
import { FormContainer } from '../../styled/containers/FormContainer';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { A } from '../../styled/miscellaneous/a';
import { click, submit, alert } from '../../utils/analytics';
import { FormInputRowField } from '../form/FormInputRow';
import { SubmitButtonRow } from '../form/SubmitButtonRow';
import { isAxiosError } from '../../utils/axiosUtils';
import { clrError, clrSuccess } from '../../styled/colors';
import { BAD_PARAMS_ERROR, TOO_MANY_REQUESTS } from '../../utils/errors';
import { FormDescriptionRow } from '../form/FormDescriptionRow';
import { SB } from '../../styled/miscellaneous/b';

const GA_CAT = 'Forgot Password';

interface IValues {
  email: string;
}

const initialValues: IValues = {
  email: '',
};

const validationSchema = object().shape({
  email: string()
    .email('Email is invalid')
    .required('Email is required'),
});

const ForgotPasswordForm: FC = () => {
  const [, sendPassResetLink] = useAxios<{ email: string }>(
    { url: '/auth/sendPassResetLink', method: 'POST' },
    { manual: true },
  );

  const [textError, setTextError] = useState('');
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <div style={{ width: 270 }}>
        Password reset link has been <SB style={{ color: clrSuccess }}>sent to your email</SB>. Be
        sure to check your SPAM folder.
      </div>
    );
  }

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors }) => {
        setTextError('');
        setSubmitting(true);
        click(GA_CAT, 'Send Reset Link');
        try {
          await sendPassResetLink({ data: variables });
          setSuccess(true);
          submit(GA_CAT, 'Send Reset Link');
        } catch (error) {
          alert(GA_CAT, 'Send Reset Link');
          if (isAxiosError(error, BAD_PARAMS_ERROR)) {
            setErrors({ email: 'Incorrect email.' });
          } else if (isAxiosError(error, TOO_MANY_REQUESTS)) {
            setTextError('Too many attempts. Please try again in 3 minutes.');
          } else {
            setTextError('Unknown server error.');
          }
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <FormContainer
          style={{ width: '270px', margin: '0 auto' }}
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormDescriptionRow style={{ marginLeft: 5, marginTop: 0 }}>
            Please enter your email address to receive a password reset link.
          </FormDescriptionRow>
          <FormInputRowField
            rowStyle={{ margin: 0 }}
            id="email"
            name="email"
            type="email"
            placeholder="Email"
          />
          <SubmitButtonRow isSubmitting={isSubmitting}>Send reset link</SubmitButtonRow>
          {textError && (
            <div style={{ color: clrError, marginTop: 10, textAlign: 'center' }}>{textError}</div>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

const ForgotPassword: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer style={{ padding: '25px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ForgotPasswordForm />
        <A
          style={{ paddingTop: 15, marginBottom: 5 }}
          onClick={() => {
            click(GA_CAT, `Back to login`);
            history.push(RouterPaths.LOG_IN);
          }}
          cursorPointer
        >
          Back to Log In
        </A>
      </div>
    </MainCardContainer>
  );
};

export default ForgotPassword;

import React, { FC } from 'react';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';

const Rules: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <H2>Rules</H2>
        <P>
          These rules are made to protect you during your time in the L2 Hestia world. By obeying
          them, you will have a better time on the server.
          <br />
          Breaking the rules will result in character/account/IP bans, depending on the situation.
        </P>

        <ol style={{ marginTop: '5px', marginBottom: '5px' }}>
          <li>
            Selling game characters, accounts, items for real life currency (real world trading) is
            forbidden.
          </li>
          <li>
            Using any 3rd party software that gives you an advantage, modifies the client or does
            damage to other players or the server itself is forbidden.
          </li>
          <li>
            Using any software or hardware that allows automated gameplay, such as botting is
            forbidden.
          </li>
          <li>
            Scamming, stealing, impersonating administration, doing fake buy(sell) shops, sending
            scam website links is forbidden.
          </li>
          <li>
            Offensive language, discrimination and other forms of verbal abuse in game chat is
            forbidden in.
          </li>
          <li>
            Advertising other projects, sending url links about other games or servers is forbidden.
          </li>
          <li>Exploitation of bugs or other unintended game mechanics is forbidden.</li>
          <li>
            Do not share your account/password to anyone. L2 Hestia administration will never ask
            for your password. If your account gets stolen, there is no guarantee that you will get
            it back.
          </li>
          <li>
            Don’t lend, share, drop your items unless you are willing to lose them. L2 Hestia
            administration does NOT return lost or stolen items (unless it was lost because of a
            server bug).
          </li>
        </ol>
      </div>
    </MainCardContainer>
  );
};

export default Rules;

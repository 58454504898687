import React, { FC } from 'react';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';

const About: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <H2>About</H2>
        <P>
          L2 Hestia is a new and uncommercial project. The aim is to give players a new and fun
          experience while playing the game we all love - Lineage2. There is no donate and the
          server is getting updates and improvements every season. Players’ comments, ideas and
          feedback are very important. New features and class balance is based on information
          gathered from previous seasons. Gl and hf!
        </P>

        <ul style={{ marginTop: '5px', marginBottom: '5px' }}>
          <li>Chronicle: Interlude (c6)</li>
          <li>Rates: xp x1000 sp x1000 aden x1000 drop x1 </li>
          <li>Enchant: success 88%, max +35, safe +4</li>
          <li>No donate</li>
          <li>1st/2nd/3rd class transfers can be bought at special npc</li>
          <li>Subclass: no quest, free (max 3)</li>
          <li>Nobbles: no quest, buy quest letter for in game currency</li>
          <li>Hero period: 1 week</li>
          <li>Increased max HP of all classes (to avoid 1 hit KO)</li>
          <li>Increased MP regen of all classes (no need to click MP potions)</li>
          <li>Every class has 2 new skills</li>
          <li>Autolearn skills</li>
          <li>NPC Buffer: 1 click to buff + buff schemes</li>
          <li>Buffs: unlimited amount, duration 2h</li>
          <li>GM Shop: supplies and items up to S grade</li>
          <li>Custom currency: HESTIA letters (dropped and spoiled by monsters)</li>
          <li>Custom NPCs, monsters</li>
          <li>Custom farm zones</li>
          <li>20 custom bosses (and their zones)</li>
          <li>Custom quest</li>
          <li>Custom weapons (Dynasty, epic)</li>
          <li>Custom armors (Dynasty)</li>
          <li>Custom tattoo (regular, elite)</li>
          <li>Custom Wings and Masks</li>
        </ul>
      </div>
    </MainCardContainer>
  );
};

export default About;

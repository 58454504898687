import React, { FC } from 'react';
import { MainCardContainer } from '../../../styled/containers/MainCardContainer';
import { H2, H4 } from '../../../styled/miscellaneous/hx';
import { Img } from '../../../styled/miscellaneous/img';
import { P } from '../../../styled/miscellaneous/p';
import { SB } from '../../../styled/miscellaneous/b';

const TheBegining: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H2>L2 Hestia: The beginning</H2>
          <P style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'right' }}>20 Jun 2020</P>
        </div>
        <Img
          icon="news/TheBegining"
          alt=""
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
          roundCorners
          shadow
        />
        <P>
          Greetings everyone,
          <br />
          I’m really happy to announce that L2 Hestia website is finally up! This is the first small
          step in the long journey to come!
        </P>
        <H4>Motivation</H4>
        <P>
          The idea of L2 Hestia has been in my mind for multiple years already. When I first started
          playing Lineage 2, it felt magical. There is just no other way to describe it. A new
          world, never seen before, filled with secrets, to be found, adventures to be had, friends
          to be made and enemies to be defeated. It seemed that no matter how much time you spend
          playing, you will always find and experience something new. I can surely say, that I had a
          blast playing Lineage 2 for good 4 to 5 years.
          <br /> However, everything has an end. Developers of original Lineage 2 seemed to be
          ignoring opinions of their players and making unpopular changes to the game which led to
          fast decline of game experience. Some say that c6 (interlude) is the last playable
          chronicle, others say its HF (High Five), some might even argue that its c4 (Scions of
          Destiny). No matter which one is it, without possibility to receive new updates, Lineage 2
          world just stopped progressing. Of course, you could play different classes, different
          servers, but it would always lead you the same path: killing the monsters you already
          killed and gaining items you already had.
          <br /> One of the possible solutions were custom servers. They offered somewhat new
          content. New zones, new ways to get items, new challenges. However most of those custom
          servers had major flaws. One of them was longevity. After 1 to 2 weeks, the server would
          die out, be wiped and started again. Doing the same things every 2 weeks is no fun. Also
          there was the p2w (pay to win) aspect. Most custom servers would allow players to get
          advantage by paying real world money, which made the game less fun for others and also
          make the paying player skip big parts of the game.
          <br /> With original Lineage 2 no longer playable and custom servers being repetitive,
          boring and even unfair I finally stopped playing Lineage 2. However, every now and then I
          would wonder how nice it would be to find a custom Lineage 2 server, which would
          constantly add new content and would give this feeling of thrill and adventure that I had
          when I first started playing it.
          <br /> And here we are. After thinking about it for multiple years and gaining coding
          knowledge needed to create a Lineage 2 server, I finally decided to do it!
        </P>
        <H4>Key features</H4>
        <P>
          L2 Hestia project is all about creating new, unseen, fair and fun Lineage 2 content.{' '}
          Server will have <SB>custom content</SB> that will be updated and improved after every
          wipe. New armor set? new weapons? new zones? new bosses? maybe even all of them after each
          wipe! Also, there will be multiple hidden secrets, easter eggs in different forms (maybe
          even references to everyone's favourite movies, TV shows or anime...). Since I don’t want
          to spoil you all the fun of discovery, I won’t go into more details for now.
          <br />
          Another key feature is <SB>no donations</SB>. I strongly believe that donations ruin game
          experience and reduce server life (if you get items too fast, you will have less things to
          do and get bored faster). Also, donations give advantage to a few players, but leave even
          more players behind. This has a negative impact on overall server mood. So, since it’s my
          afterwork project, I won’t be trying to get any money from it (of course, I will accept
          support for my work, but it won’t give players anything more than cosmetic items).
          <br />
          As for the chronicle, <SB>interlude (c6)</SB> was chosen, since it’s one of people's
          favourite and most stable chronicles. Also it is quite easy to modify and add custom
          features.
          <br />
          Lastly, I would like to mention that server content will depend (as much as it’s possible)
          on <SB>players wishes</SB>. Meaning votes for balance/content/quality of life updates.
          Runescape classic has adapted this type of changes system and so far, it seemed to serve
          them very well.
        </P>
        <H4>Time</H4>
        <P>
          I am the only one working on this project (at least for now). This is also my after work
          activity, which means it will take time. This idea of an amazing server cannot be realized
          in one day and it will take some time for it to get there. Everything will start small,
          with the inspiration from one of the best custom server that I’ve played before (if by any
          chance “Umbalas” is one of the people reading this, I’d be honored to speak with him,
          since his server gave me my inspiration to do this).
          <br /> Next post will include a roadmap and bigger picture on what’s going to be done.
          Also you can expect a new post every time something new gets done. Best wishes! And I
          hope, one day, we can all meet in the world of L2 Hestia.
        </P>
      </div>
    </MainCardContainer>
  );
};

export default TheBegining;

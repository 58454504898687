import React, { FC } from 'react';
import { FooterContainer } from '../styled/containers/FooterContainer';
import { Img } from '../styled/miscellaneous/img';
import { facebookUrl, youtubeUrl, discordUrl } from '../consts';
import { click } from '../utils/analytics';

const socialIconHeight = '30px';
const GA_CAT = 'Footer';

const Footer: FC = () => {
  return (
    <FooterContainer>
      <div style={{ display: 'flex', marginBottom: '25px' }}>
        <Img
          height={socialIconHeight}
          onClick={() => {
            click(GA_CAT, 'Facebook');
            window.open(facebookUrl);
          }}
          icon="logos/Facebook"
          pointer
        />
        <Img
          height={socialIconHeight}
          onClick={() => {
            click(GA_CAT, 'Discord');
            window.open(discordUrl);
          }}
          icon="logos/Discord"
          style={{ margin: '0 15px' }}
          pointer
        />
        <Img
          height={socialIconHeight}
          onClick={() => {
            click(GA_CAT, 'Youtube');
            window.open(youtubeUrl);
          }}
          icon="logos/Youtube"
          pointer
        />
      </div>
      © 2020 - {new Date().getFullYear()} Lineage2 Hestia.
    </FooterContainer>
  );
};

export default Footer;

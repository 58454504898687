import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { clrDarkBlue1, clrDividerMuted, clrError } from '../../styled/colors';
import { FormRow } from '../../styled/containers/FormContainer';
import { getNestedValueByString } from '../../utils/formikUtils';
import { ErrorRowMessage } from './ErrorRowMessage';
import { InputBlock } from './input-block';

interface IProps extends FieldProps {
  rowStyle?: React.CSSProperties;
  errorRowStyle?: React.CSSProperties;
  afterChange?(): void;
  rightElement?: JSX.Element;
}

const FormInputRow = ({
  field: { onBlur, ...field },
  form: { touched, errors },
  rowStyle,
  errorRowStyle,
  afterChange,
  rightElement,
  ...props
}: IProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const error =
    getNestedValueByString(touched, field.name) && getNestedValueByString(errors, field.name)
      ? true
      : false;

  return (
    <>
      <FormRow
        style={
          rightElement
            ? {
                display: 'flex',
                borderRadius: 3,
                alignItems: 'center',
                boxSizing: 'border-box',
                border: `solid 1px ${
                  error ? clrError : isFocused ? clrDarkBlue1 : clrDividerMuted
                }`,
                ...rowStyle,
              }
            : rowStyle
        }
      >
        <InputBlock
          error={error}
          onFocus={() => setIsFocused(true)}
          onBlur={e => {
            setIsFocused(false);
            onBlur(e);
          }}
          {...field}
          {...props}
          noBorder={!!rightElement}
          onChange={e => {
            field.onChange(e);
            afterChange && afterChange();
          }}
        />
        {rightElement}
      </FormRow>
      <ErrorRowMessage name={field.name} rowStyle={errorRowStyle} />
    </>
  );
};

export const FormInputRowField = (props: any) => <Field {...props} component={FormInputRow} />;

import React, { FC } from 'react';
import { RouterPaths } from '../../../routes/RouterPaths';
import NewsPreview from '../NewsPreview';

const SocialMediaPreview: FC = () => {
  return (
    <NewsPreview
      redirectPath={RouterPaths.SOCIAL_MEDIA_HERE}
      title={'Social media is here!'}
      img="news/SocialMediaPrev"
      dateStr="24 Jun 2020"
    >
      Good day,
      <br />
      I'd like to announce that L2 Hestia can now be found on Facebook, Discord and Youtube. That's
      right, there's more ways to follow L2 Hestia progress, communicate and give feedback.
      <br />I can usually be found at discord, so feel free to say "Hi" and ask any questions you
      got there!
      <br />
    </NewsPreview>
  );
};

export default SocialMediaPreview;

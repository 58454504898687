import * as React from 'react';
import { clrError } from '../../styled/colors';
import { Hint, IInputProps, InfoBox, Input, InputWrap, Label } from '../../styled/form/input';

export interface IInputBlockProps extends IInputProps {
  value?: string;
  style?: React.CSSProperties;
  name?: string;
  className?: string;
  label?: string;
  id?: string;
  error?: boolean;
  type?: string;
  readOnly?: boolean;
  infoBoxText?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoComplete?: string;
  ref?: React.RefObject<any>;
  hint?: string;
  required?: boolean;
  button?: React.ReactNode;
}

export const InputBlock = ({
  style,
  className,
  label,
  id,
  type,
  value,
  error,
  infoBoxText,
  readOnly,
  hint,
  required,
  button,
  noBorder,
  ...props
}: IInputBlockProps) => {
  const labelContent = (
    <Label noColor htmlFor={id}>
      {label}
      {required && <span style={{ color: clrError }}>{' *'}</span>}
    </Label>
  );
  const inputContent = (
    <Input
      id={id}
      value={value}
      type={type || 'text'}
      readOnly={readOnly}
      invalid={error}
      required={type === 'date'} // removes x from date select
      noBorder={noBorder}
      {...props}
    />
  );
  return (
    <InputWrap style={style} className={className}>
      {label && labelContent}
      {hint && <Hint>{hint}</Hint>}
      {button ? (
        <div style={{ display: 'flex' }}>
          {inputContent}
          {button}
        </div>
      ) : (
        inputContent
      )}
      {infoBoxText && <InfoBox>{infoBoxText}</InfoBox>}
    </InputWrap>
  );
};

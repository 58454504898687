import styled from 'styled-components';
import { IResponsiveProps, responsiveProps } from '../utils';

interface IDropdownHeaderMenuProps extends IResponsiveProps {}

export const DropdownHeaderMenu = styled.div<IDropdownHeaderMenuProps>`
  ${responsiveProps};
  top: 71px;
  left: 22px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(180deg, rgba(27, 40, 62, 0.9) 45%, rgba(20, 26, 31, 0.9) 100%);
  border-radius: 0 0 6px 6px;
`;

import React, { FC } from 'react';
import { discordUrl, facebookUrl, youtubeUrl } from '../../../consts';
import { MainCardContainer } from '../../../styled/containers/MainCardContainer';
import { A } from '../../../styled/miscellaneous/a';
import { H2, H4 } from '../../../styled/miscellaneous/hx';
import { Img } from '../../../styled/miscellaneous/img';
import { P } from '../../../styled/miscellaneous/p';
import { click } from '../../../utils/analytics';

const SocialMedia: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H2>Social media is here!</H2>
          <P style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'right' }}>24 Jun 2020</P>
        </div>
        <Img
          icon="news/SocialMedia"
          alt=""
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
          roundCorners
          shadow
        />
        <P>
          Good day,
          <br />
          I'd like to announce that L2 Hestia can now be found on Facebook, Discord and Youtube.
          That's right, there's more ways to follow L2 Hestia progress, communicate and give
          feedback.
          <br />I can usually be found at discord, so feel free to say "Hi" and ask any questions
          you got there!
        </P>
        <H4>Links</H4>
        <A
          href={facebookUrl}
          target="_blank"
          onClick={() => click('NewsSocialMedia', 'facebook')}
          rel="noopener noreferrer"
        >
          L2 Hestia facebook page
        </A>
        <br />
        <A
          href={discordUrl}
          target="_blank"
          onClick={() => click('NewsSocialMedia', 'discord')}
          rel="noopener noreferrer"
        >
          L2 Hestia Discord channel
        </A>
        <br />
        <A
          href={youtubeUrl}
          target="_blank"
          onClick={() => click('NewsSocialMedia', 'youtube')}
          rel="noopener noreferrer"
        >
          L2 Hestia Youtube channel
        </A>
        <br />
        Cheers!
      </div>
    </MainCardContainer>
  );
};

export default SocialMedia;

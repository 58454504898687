import styled from 'styled-components';
import { untilDesktop } from '../utils';

export interface IFlexSplitProps {
  parts?: number;
  partsMobile?: number;
}

export const FlexSplit = styled.div<IFlexSplitProps>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    flex: 0 0 100%;
    ${props => props.parts && `flex-basis: ${(101 - props.parts) / props.parts}%;`}
    ${props =>
      props.partsMobile &&
      untilDesktop(`flex-basis: ${(101 - props.partsMobile) / props.partsMobile}%;`)}
  }
`;

interface IFlexDivProps {
  column?: boolean;
  w100?: boolean;
  noWrap?: boolean;
}

export const FlexDiv = styled.div<IFlexDivProps>`
  display: flex;
  ${props => !props.noWrap && `flex-wrap: wrap;`}
  ${props => props.w100 && `width: 100%;`}
  ${props => props.column && `flex-direction: column;`}
`;

import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { object, string } from 'yup';
import { useAuth } from '../../hooks/useAuth';
import { clrError, clrSuccess } from '../../styled/colors';
import { FormContainer } from '../../styled/containers/FormContainer';
import { click, submit, alert } from '../../utils/analytics';
import { isAxiosError } from '../../utils/axiosUtils';
import { BAD_PASS_ERROR, TOO_MANY_REQUESTS } from '../../utils/errors';
import { FormInputRowField } from '../form/FormInputRow';
import { SubmitButtonRow } from '../form/SubmitButtonRow';

const GA_CAT = 'Account';

interface IValues {
  oldPassword: string;
  newPassword: string;
}

const initialValues: IValues = {
  oldPassword: '',
  newPassword: '',
};

const validationSchema = object().shape({
  newPassword: string()
    .min(8, 'New password must be at least 8 characters long')
    .max(15, 'New password can not be longer than 15 characters')
    .matches(/[A-Z]/, 'New password must include at least one upper-case letter')
    .matches(/[a-z]/, 'New password must include at least one lower-case letter')
    .matches(/[0-9]/, 'New password must include at least one number')
    .matches(
      /[@.,$!%*#?&]/,
      'New password must include at least one special character (@.,$!%*#?&)',
    )
    .required('New password is required'),
  oldPassword: string().required('Old password is required'),
});

const ChangePasswordForm: FC = () => {
  const { token } = useAuth();
  const [, changePass] = useAxios<boolean>(
    { url: '/auth/changePass', method: 'POST', headers: { authorization: token } },
    { manual: true },
  );

  const [textError, setTextError] = useState('');
  const [success, setSuccess] = useState(false);

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors, resetForm }) => {
        setSuccess(false);
        setTextError('');
        setSubmitting(true);
        click(GA_CAT, 'Change Password');
        try {
          await changePass({ data: variables });
          setSuccess(true);
          resetForm();
          submit(GA_CAT, 'Change Password');
        } catch (error) {
          alert(GA_CAT, 'Change Password');
          if (isAxiosError(error, TOO_MANY_REQUESTS)) {
            setTextError('Too many attempts. Please try again in 5 minutes.');
          } else if (isAxiosError(error, BAD_PASS_ERROR)) {
            setErrors({ oldPassword: 'Incorrect Old password.' });
          } else {
            setTextError('Unknown server error.');
          }
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <FormContainer
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormInputRowField
            id="oldPassword"
            name="oldPassword"
            type="password"
            placeholder="Old password"
          />
          <FormInputRowField
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="New password"
          />
          <SubmitButtonRow fullW slim isSubmitting={isSubmitting}>
            Change Password
          </SubmitButtonRow>
          {textError && (
            <div style={{ color: clrError, marginTop: 10, textAlign: 'center' }}>{textError}</div>
          )}
          {success && (
            <div style={{ color: clrSuccess, marginTop: 10, textAlign: 'center' }}>
              Your password has been reset.
            </div>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;

export enum RouterPaths {
  ROOT = '/',
  HOME = '/home',
  ABOUT = '/about',
  DOWNLOADS = '/downloads',
  RULES = '/rules',
  FORUM = '/forum',
  FAQ = '/faq',
  PLAY_NOW = '/play-now',
  THE_BEGINING = '/the-begining',
  SOCIAL_MEDIA_HERE = '/social-media-here',
  ROAD_MAP = '/news-roadmap',
  TEST_SERVER = '/news-test-server',
  LOG_OUT = '/logout',
  LOG_IN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:link',
  RESEND_VERIFICATION = '/resend-verification',
  ACCOUNT = '/account',
}

export const exact = true;

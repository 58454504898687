import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { clinetLinkV1, patchLinkV1 } from '../../../consts';
import { RouterPaths } from '../../../routes/RouterPaths';
import { MainCardContainer } from '../../../styled/containers/MainCardContainer';
import { A } from '../../../styled/miscellaneous/a';
import { H2 } from '../../../styled/miscellaneous/hx';
import { Img } from '../../../styled/miscellaneous/img';
import { P } from '../../../styled/miscellaneous/p';
import { click } from '../../../utils/analytics';

export const TITLE = 'Test server is Up!';
export const DATE = '05 July 2020';

const TestServer: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H2>{TITLE}</H2>
          <P style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'right' }}>{DATE}</P>
        </div>
        <Img
          icon="news/TestServer"
          alt=""
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
          roundCorners
          shadow
        />
        <P>
          Hello everyone,
          <br />
          I’m happy to announce that things are moving faster than expected and the test server is
          up and running! Feel free to join!
          <br />
          You can download the new patch{' '}
          <A
            href={patchLinkV1}
            target="_blank"
            onClick={() => click('Download', 'Patch-Mega')}
            rel="noopener noreferrer"
          >
            here
          </A>
          .
          <br />
          You can download the interlude client{' '}
          <A
            href={clinetLinkV1}
            target="_blank"
            onClick={() => click('Download', 'Client-Yandex')}
            rel="noopener noreferrer"
          >
            here
          </A>
          .
          <br />
          More information on how to connect and play can be found in the “
          <A
            onClick={() => {
              click('TestServer', `PlayNow`);
              history.push(RouterPaths.PLAY_NOW);
            }}
            cursorPointer
          >
            Play Now
          </A>
          ” section.
          <br />
          <br />
          Cheers!
        </P>
      </div>
    </MainCardContainer>
  );
};

export default TestServer;

export const facebookUrl = 'https://www.facebook.com/L2HestiaOfficial/';
export const youtubeUrl = 'https://www.youtube.com/channel/UCmLntCrSLPA1WJS17Wk9c7A/';
export const discordUrl = 'https://discord.gg/ajJzdJN';

export const patchLinkV1 =
  'https://mega.nz/file/hpxwVLLb#1BpDJsQJGaMXmJBsiZMliD0sQqZiW3lwBklFuQgQfs8';
export const clinetLinkV1 = 'https://yadi.sk/d/R8JjnS1b8akZVQ';

const PRODUCTION_HOSTNAME = 'l2hestia.com';
export const IS_PRODUCTION = window.location.hostname.includes(PRODUCTION_HOSTNAME);

export const mobileSize = 1000;

export const BACKEND_PORT = '10200';
export const BACKEND_URL = IS_PRODUCTION
  ? `https://${PRODUCTION_HOSTNAME}/api`
  : `http://localhost:${BACKEND_PORT}`;

export const MS_IN_S = 1000;
export const MS_IN_MIN = MS_IN_S * 60;
export const MS_IN_H = MS_IN_MIN * 60;
export const MS_IN_DAY = MS_IN_H * 24;
export const MS_IN_YEAH = MS_IN_DAY * 365;

import React, { FC } from 'react';
import { RouterPaths } from '../../../routes/RouterPaths';
import NewsPreview from '../NewsPreview';

import { DATE, TITLE } from '../pages/TestServer';

const TestServerPreview: FC = () => {
  return (
    <NewsPreview
      redirectPath={RouterPaths.TEST_SERVER}
      title={TITLE}
      img="news/TestServerPrev"
      dateStr={DATE}
    >
      Hello everyone,
      <br />
      I’m happy to announce that things are moving faster than expected and the test server is up
      and running! Feel free to join!
    </NewsPreview>
  );
};

export default TestServerPreview;

import * as React from 'react';
import { FormRow } from '../../styled/containers/FormContainer';
import { P } from '../../styled/miscellaneous/p';

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  style?: React.CSSProperties;
}

export const FormDescriptionRow: React.FC<IProps> = ({ children, style }) => (
  <FormRow style={style}>
    <P >{children}</P>
  </FormRow>
);

import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { RouterPaths } from '../../routes/RouterPaths';
import { FormContainer } from '../../styled/containers/FormContainer';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { A } from '../../styled/miscellaneous/a';
import { alert, click, submit } from '../../utils/analytics';
import { FormInputRowField } from '../form/FormInputRow';
import { SubmitButtonRow } from '../form/SubmitButtonRow';
import { isAxiosError } from '../../utils/axiosUtils';
import { clrError, clrSuccess } from '../../styled/colors';
import { TOO_MANY_REQUESTS } from '../../utils/errors';
import { FormDescriptionRow } from '../form/FormDescriptionRow';
import { SB } from '../../styled/miscellaneous/b';

const GA_CAT = 'Reset Password';

interface IValues {
  newPassword: string;
}

const initialValues: IValues = {
  newPassword: '',
};

const validationSchema = object().shape({
  newPassword: string()
    .min(8, 'Password must be at least 8 characters long')
    .max(50, 'Password can not be longer than 50 characters')
    .matches(/[A-Z]/, 'Password must include at least one upper-case letter')
    .matches(/[a-z]/, 'Password must include at least one lower-case letter')
    .matches(/[0-9]/, 'Password must include at least one number')
    .matches(/[@.,$!%*#?&]/, 'Password must include at least one special character (@.,$!%*#?&)')
    .required('Password is required'),
});

const ResetPasswordForm: FC = () => {
  const history = useHistory();
  const { link } = useParams<{ link?: string }>();
  const [{ error, data: isLinkValid }] = useAxios<boolean>({
    url: `/auth/isPassResetLinkValid/${link}`,
    method: 'GET',
  });

  const [, resetPass] = useAxios<boolean>(
    { url: '/auth/resetPass', method: 'POST' },
    { manual: true },
  );

  const [textError, setTextError] = useState('');
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <div style={{ width: 270 }}>
        <SB style={{ color: clrSuccess }}>Your password has been reset.</SB>
      </div>
    );
  }

  if (error || !isLinkValid) {
    return (
      <div style={{ width: 270, color: clrError }}>
        Password reset link expired. Click{' '}
        <A cursorPointer noUnderline onClick={() => history.push(RouterPaths.FORGOT_PASSWORD)}>
          here
        </A>{' '}
        to get a new one.
      </div>
    );
  }

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors }) => {
        setTextError('');
        setSubmitting(true);
        click(GA_CAT, 'Reset Password');
        try {
          await resetPass({ data: { password: variables.newPassword, link } });
          setSuccess(true);
          submit(GA_CAT, 'Reset Password');
        } catch (error) {
          alert(GA_CAT, 'Reset Password');
          if (isAxiosError(error, TOO_MANY_REQUESTS)) {
            setTextError('Too many attempts. Please try again in 3 minutes.');
          } else {
            setTextError('Unknown server error.');
          }
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <FormContainer
          style={{ width: '270px', margin: '0 auto' }}
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormDescriptionRow style={{ marginLeft: 5, marginTop: 0 }}>
            Please enter your NEW password.
          </FormDescriptionRow>
          <FormInputRowField
            rowStyle={{ margin: 0 }}
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="New password"
          />
          <SubmitButtonRow isSubmitting={isSubmitting}>Reset Password</SubmitButtonRow>
          {textError && (
            <div style={{ color: clrError, marginTop: 10, textAlign: 'center' }}>{textError}</div>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

const ResetPassword: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer style={{ padding: '25px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ResetPasswordForm />
        <A
          style={{ paddingTop: 15, marginBottom: 5 }}
          onClick={() => {
            click(GA_CAT, `Back to login`);
            history.push(RouterPaths.LOG_IN);
          }}
          cursorPointer
        >
          Back to Log In
        </A>
      </div>
    </MainCardContainer>
  );
};

export default ResetPassword;

export const clrWhite = '#ffffff';
export const clrBck = '#e5e5e5';

export const clrBlue = '#1b283e';
export const clrBlueLight = '#449DCC';
export const clrBluishLight = `#e8f9ff`;

export const clrBluish = '#80c0d2';
export const clrBluishAccent = '#a7ced4';
export const clrBluishHover = '#60b0c6';
export const clrBluishDark = '#7abbcd';
export const clrBluishAttention = '#70a9b9';
export const clrBluishViolet = '#6370F9';

export const clrSuccess = '#1b981a';
export const clrWarning = '#ffd857';
export const clrError = '#ec464a';
export const clrInfo = '#33ccff';

export const clrDivider = '#eef4f4';
export const clrDividerMuted = '#d9e6e6';

export const clrGreenish = '#e4f9f8';
export const clrGreenishAccent = '#8ecdc2';
export const clrGreenishMuted = '#dee7e7';
export const clrGreenishLight = '#eef4f4';
export const clrGreenishHover = '#7db8ae';
export const clrGreenishDark = '#7dbcb1';
export const clrGreenishAttention = '#82c7d2';
export const clrGreenishDisabled = '#c7c7c7';

export const clrWhiteMist = '#fbfdfd';

export const clrText = '#3C4858';
export const clrTextBlur = '#777777';
export const clrTextDark = '#233549';
export const clrTextMuted = '#859a9a';
export const clrTextGreenish = '#7cb9b1';
export const clrBlueText = '#0de7ff';

export const clrBackgroundDisabled = '#cccccc';

export const clrRedishAccent = '#e17a7a';
export const clrRedishHover = '#d44040';
export const clrRed = '#EE2626';
export const clrRedLight = '#ff4949';

export const clrGreenDark = '#20691b';
export const clrGreenDark1 = `#143c13`;
export const clrGreenDark2 = '#151f14';

export const clrDarkBlue1 = '#1b283e';
export const clrDarkBlue2 = '#141a1f';

import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { A } from '../styled/miscellaneous/a';
import { Hr } from '../styled/miscellaneous/hr';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';
import { click } from '../utils/analytics';
import TheBeginingPreview from './news/previews/TheBeginingPreview';
import SocialMediaPreview from './news/previews/SocialMediaPreview';
import RoadMapPreview from './news/previews/RoadmapPreview';
import TestServerPreview from './news/previews/TestServerPreview';

const news = [
  <TestServerPreview />,
  <RoadMapPreview />,
  <SocialMediaPreview />,
  <TheBeginingPreview />,
];

const Home: FC = () => {
  const history = useHistory();
  return (
    <MainCardContainer>
      <div style={{ fontSize: 16, lineHeight: '24px' }}>
        <H2>Welcome to the World of L2-Hestia</H2>
        <P style={{ marginBottom: '0px' }}>
          L2 Hestia is a Custom PvP Server on interlude(C6) platform. <br />
          Key features:
        </P>

        <ul style={{ marginTop: '5px', marginBottom: '5px' }}>
          <li>All rates x1000.</li>
          <li>Custom items/npcs/zones.</li>
          <li>No donate.</li>
          <li>Player-driven content updates.</li>
        </ul>
        <P>
          Game patch can be found in the{' '}
          <A
            onClick={() => {
              click('Home', `Downloads`);
              history.push(RouterPaths.DOWNLOADS);
            }}
            cursorPointer
          >
            Downloads
          </A>{' '}
          section. Game accounts are auto created.
          <br />
          For detailed explanation on how to start playing,{' '}
          <A
            onClick={() => {
              click('Home', `Play now`);
              history.push(RouterPaths.PLAY_NOW);
            }}
            cursorPointer
          >
            click here
          </A>
          .
        </P>
        <Hr />
        {news.map((element, i) => (
          <div key={i}>
            {element}
            <Hr />
          </div>
        ))}
      </div>
    </MainCardContainer>
  );
};

export default Home;

import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { object, string } from 'yup';
import { useAuth } from '../../hooks/useAuth';
import { RouterPaths } from '../../routes/RouterPaths';
import { clrError, clrSuccess } from '../../styled/colors';
import { FormContainer } from '../../styled/containers/FormContainer';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { A } from '../../styled/miscellaneous/a';
import { alert, click, submit } from '../../utils/analytics';
import { isAxiosError } from '../../utils/axiosUtils';
import { BAD_EMAIL_PASS_ERROR, TOO_MANY_REQUESTS } from '../../utils/errors';
import { FormInputRowField } from '../form/FormInputRow';
import { SubmitButtonRow } from '../form/SubmitButtonRow';
import { REG_SUCCESS } from './Register';

const GA_CAT = 'Login';
const BAD_EMAIL_PASS_MSG = 'Wrong email or pass.';

interface IValues {
  email: string;
  password: string;
}

const initialValues: IValues = {
  email: '',
  password: '',
};

const validationSchema = object().shape({
  email: string()
    .email('Email is invalid')
    .required('Email is required'),
  password: string().required('Password is required'),
});

const LoginForm: FC = () => {
  const [, loginPost] = useAxios<{ token: string }>(
    { url: '/auth/login', method: 'POST' },
    { manual: true },
  );

  const { setToken } = useAuth();
  const [textError, setTextError] = useState('');

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors }) => {
        setTextError('');
        setSubmitting(true);
        click(GA_CAT, 'Login');
        try {
          const response = await loginPost({ data: variables });
          submit(GA_CAT, 'Login');
          setToken(response.data.token);
        } catch (error) {
          alert(GA_CAT, 'Login');
          if (isAxiosError(error, BAD_EMAIL_PASS_ERROR)) {
            setErrors({ password: BAD_EMAIL_PASS_MSG, email: BAD_EMAIL_PASS_MSG });
          } else if (isAxiosError(error, TOO_MANY_REQUESTS)) {
            setTextError('Too many login attempts. Try again later.');
          } else {
            setTextError('Unknown server error.');
          }
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <FormContainer
          style={{ width: '270px', margin: '0 auto' }}
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormInputRowField
            rowStyle={{ margin: 0 }}
            id="email"
            name="email"
            type="email"
            placeholder="Email"
          />
          <FormInputRowField id="password" name="password" type="password" placeholder="Password" />
          <SubmitButtonRow isSubmitting={isSubmitting}>Log in</SubmitButtonRow>
          {textError && (
            <div style={{ color: clrError, marginTop: 10, textAlign: 'center' }}>{textError}</div>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

const Login: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const regSuccess = urlSearchParams.get('reg') === REG_SUCCESS;
  return (
    <MainCardContainer style={{ padding: '25px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {regSuccess && (
          <div style={{ color: clrSuccess, marginBottom: 15, textAlign: 'center' }}>
            Registration successful!
          </div>
        )}
        <LoginForm />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <A
            style={{ paddingTop: 15, marginBottom: 5 }}
            onClick={() => {
              click(GA_CAT, `Forgot password`);
              history.push(RouterPaths.FORGOT_PASSWORD);
            }}
            cursorPointer
          >
            Forgot Password?
          </A>
          <A
            style={{ paddingTop: 15, marginBottom: 5 }}
            onClick={() => {
              click(GA_CAT, `Register now`);
              history.push(RouterPaths.REGISTER);
            }}
            cursorPointer
          >
            Register Now
          </A>
        </div>
      </div>
    </MainCardContainer>
  );
};

export default Login;
